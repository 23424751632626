:root {
  --tw-bg-opacity: 0.2;
  --purple: #a855f7;
  --purple-light: #c084fc51;
  --base-color: #007aff;
  --base-color-light: rgb(59 130 246 / var(--tw-bg-opacity));
  --tw-bg-opacity2: 1;
  --input-color-light: rgb(241 245 249 / var(--tw-bg-opacity2));
  --input-color: #3a5aff;
  --yellow-dark: #ffc00a;
  --yellow-light: #ffc43b;
  --yellow-theme: #facc15;
  --yellow-theme-light: rgb(234 179 8 / var(--tw-bg-opacity));
  --red-light: #f43f5e;
  --red-light-low: rgb(244 63 94 / var(--tw-bg-opacity));
  --blue-dark: #364253;
  --blue-light: #00306e;
  --white: #fff;
  --text-color: #6b7280;
  --border-color: #e5e7eb;
  --background-color: #f8fafc;
  --background-color-dark: #f3f3f4;
  --dash-text-color: #333;
  --main-font-size: 32px;
}
html {
  scroll-behavior: smooth;
  line-height: 1.5;
}
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--base-color);
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background: var(--base-color-light);
  border-radius: 20px;
}

* {
  box-sizing: border-box;
  font-family: "Cairo", sans-serif !important;
  padding: 0;
  margin: 0;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  text-decoration: none;
}
.bg-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}
.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}
.animate {
  opacity: 0; /* Initially hide the component */
  animation: fade-in 0.5s ease-in forwards; /* Apply the fade-in animation */
}
.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.side-nav-link {
  flex: 1;
  color: var(--blue-dark);
}
.side-nav-link svg {
  color: var(--blue-dark);
}

.side-nav-list .active {
  background-color: var(--background-color);
  color: var(--base-color);
  border-radius: 10px;
}
.side-nav-list .active svg {
  fill: var(--base-color);
}
.swal-modal .swal-text {
  text-align: center;
}